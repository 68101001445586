import { Link } from "react-router-dom"
import "./Contact.css"

export default function Contact() {
  return (
    <div id="contactContainer">
        
        <div id="contactTitle">
          <div className="spectral-bold" id="more">links</div>
        </div>

        <Link to="https://www.linkedin.com/in/joelle-li-cho/" target="_blank">
          <div className="contact-circle">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/linkedin/linkedin-plain.svg" alt="linkedin icon" className="icon"/>
            <div className="spectral-bold">in/joelle-li-cho</div>
          </div>
        </Link>

        <Link to="https://github.com/JoelleLi" target="_blank">
          <div className="contact-circle">
            <img className="icon" src={require("./github.png")} alt="" height="20px" width="20px"/>
            <div className="spectral-bold">github.com/joelleli</div>
          </div>
        </Link>

        <div id="contactTitle">
          <div className="spectral-bold" id="more">say hello...</div>
        </div>

        <Link to="mailto:joelle.licho@rocketmail.com" target="_blank">
          <div className="contact-circle">
            <img className="icon" src={require("./email.png")} alt="" height="20px" width="20px"/>
            <div className="spectral-bold">joelle.licho@rocketmail.com</div>
          </div>
        </Link>

        <div id="home-wrapper-contact">
          <Link to="/" id="home-button-contact">
            <div>home</div>
          </Link>
        </div>

    </div>
    
  )
}
