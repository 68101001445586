import "./Birthdays.css"

export default function Birthdays() {
  return (
    <div className="projDescContainer">
      <div className="projAbout spectral-medium">
        <p id="firstProjDesc" >Using Python, Django, PostgreSQL and JavaScript and to produce a full-stack CRUD birthday diary application in React, implementing Django authentication and AWS image upload.</p>

        <div className='jobWrapper'>
          <div id="aboutCircle" className="circle"></div>
          <p className="bulletText">Be reminded of upcoming birthdays</p>
        </div>

        <div className='jobWrapper'>
          <div id="aboutCircle" className="circle"></div>
          <p className="bulletText">Keep track of who you still need to get cards and presents for</p>
        </div>

        <div className='jobWrapper'>
            <div id="aboutCircle" className="circle"></div>
            <p className="bulletText">Create publicly shareable wishlists and birthday profiles for yourself and others</p>
        </div>
      </div> 

      <div className="projImageMobileWrapper">
        <div className="projImageTextWrapper">
          <img src={require("./birthdays03.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Be reminded of upcoming birthdays</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./birthdays01.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Keep track of birthdays and tick as done when you've got the card or present</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./birthdays02.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Make wishlists for yourself and others</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./birthdays04.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Add friends and familys birthday information to your diary</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./birthdays05.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Edit and add multiple images to each wish</p>
        </div>
      </div>

      <div id="projSkillsWrapper">
        <div className="innerProjSkillswrapper">
          {/* <h4>Back End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Back End</div>
          </div>

          <div className="descSkills">

            <div className="skillWrapper">
              <i className="devicon-python-plain"></i>
              <p className="spectral-semibold skillText">Python</p>
            </div>
            <div className="skillWrapper">
              <i className="devicon-django-plain"/>
              <p className="spectral-semibold skillText">Django</p>
            </div>
            <div className="skillWrapper">
            <i class="devicon-djangorest-plain"></i>
              <p className="spectral-semibold skillText">Django Rest Framework</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-postgresql-plain"></i>
              <p className="spectral-semibold skillText">PostgreSQL</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-amazonwebservices-plain-wordmark"></i>
              <p className="spectral-semibold skillText">AWS</p>
            </div>

          </div>
        </div>

        <div className="innerProjSkillswrapper">
          {/* <h4>Front End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Front End</div>
          </div>

          <div className="descSkills">

          <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-react-original"></i>
              <p className="spectral-semibold skillText">React</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-axios-plain-wordmark"></i>
              <p className="spectral-semibold skillText">Axios</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-css3-plain"></i>
              <p className="spectral-semibold skillText">CSS3</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-html5-plain"></i>
              <p className="spectral-semibold skillText">HTML5</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-tailwindcss-original"></i>
              <p className="spectral-semibold skillText">TailwindCSS</p>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}
