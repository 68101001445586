import "./ProjectCard.css"

export default function ProjectCard({project}) {
    const cardStyle = {
        backgroundImage: `url("${process.env.PUBLIC_URL}${project.image}")`,  
        backgroundSize: "cover",
        backgroundPosition: "center",
    }

  return (
    <div className="projectCardWrapper">
      <div className='projectCard' style={cardStyle}>
      </div>
      <h2 className="spectral-bold-italic">{project.title}</h2>
    </div>
  )
}
