import { Link } from "react-router-dom"
import "./Experience.css"

export default function Experience() {
  return (
    <div id='experienceContainer'>
        <div className="jobBox">
            <div className="jobHeader">
                <img className="jobLogo" src="https://seeklogo.com/images/G/general-assembly-logo-D5C634F07A-seeklogo.com.png" alt="GA Logo" width="50px"/>
                <div className="jobTitle">
                    <h3 className="jobTitleMargin">Student, Software Engineering Immersive, General Assembly (Remote)</h3>
                    <h4 className="jobTitleMargin">November 2023 - March 2024</h4>
                </div>
            </div>
            <p className="spectral-medium">Full time 12 week transformative course where I learnt a solid base of fundamental programming languages and frameworks, going from zero coding experience to building full-stack applications.</p>

            <p className="spectral-medium">Day to day classes included daily stand-ups, lectures, labs, and projects in teams and individually.</p>
            <p className="spectral-medium">To see the projects I worked on at General Assembly, click
                <Link to="/projects" className="here"> here</Link>
            </p>
        </div>
        <div className="jobBox">
            <div className="jobHeader">
                <img className="jobLogo" src="https://cdn.vectorstock.com/i/1000x1000/09/45/film-icon-symbol-flat-vector-5270945.webp" alt="film icon" width="50px"/>
                <div className="jobTitle">
                    <h3 className="jobTitleMargin">Freelance Costume Maker, London</h3>
                    <h4 className="jobTitleMargin">Apr 2019 - Aug 2023</h4>
                </div>
            </div>

            <p className="spectral-medium">Working to big deadlines in a team to produce a wide range of innovative, functional and visually stunning costumes for the principle cast in major motion pictures, ranging from period to modern sci-fi.</p>
            <p className="spectral-medium">Using a wide range of industrial machinery and techniques and specialising in contemporary and traditional tailoring, as well as performing alterations and fittings on set, pattern cutting and training juniors/ trainees.</p>
            <h4>Costume Maker, Feb 2021 - Aug 2023</h4>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">Deadpool 3, Richmond Street Productions Ltd., March 2023 - Aug 2023</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">Six Triple Eight, Six Triple Eight Productions LTD, Jan 2023 - March 2023</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">Mickey 17, Funfair Films Ltd., Apr 2022 - Dec 2022</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">Wonka, Narrow Mark Films Ltd., Jun 2021 - Jan 2022</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">The Little Mermaid, Sand Castle Pictures Ltd., Feb 2021 - May 2021</p>
            </div>
            <h4>Junior Costume Maker, Jun 2019 - Jan 2021</h4>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">The Batman, Articulated Productions Ltd., Nov 2019 - Jan 2021</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">No Time To Die, B25 Ltd., Jun 2019 - Nov 2019</p>
            </div>
            <div className='jobWrapper'>
                <div id="aboutCircle" className="circle"></div>
                <p className="jobText spectral-medium">Morbius, May 2019</p>
            </div>
        </div>
        <div className="jobBox">
            <p className="spectral-medium">For a full career breakdown, check out my 
                <Link to="https://www.linkedin.com/in/joelle-li-cho/" target="_blank" className="here"> LinkedIn </Link>
                profile.
            </p>
        </div>
        <div id="home-wrapper-experience">
            <Link to="/" id="home-button-skills">
            <div>home</div>
            </Link>
        </div>
    </div>
  )
}
