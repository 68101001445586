import React from 'react'

export default function CCC() {
  return (
    <div className="projDescContainer">
      <div className="projAbout spectral-medium">
        <p id="firstProjDesc">Crash Course Collective is a community where users can view profiles and projects from their fellow students in their course cohort, and past alumni. </p>
        <p id="firstProjDesc">Working in a team of three to produce a single page full stack application using MongoDB, Express, React, and Node.js, using a GitHub API for user login credentials.</p>
        
        <div className='jobWrapper'>
            <div id="aboutCircle" className="circle"></div>
            <p className="bulletText">View profiles and projects from your fellow students in your course cohort and past alumni</p>
        </div>
        <div className='jobWrapper'>
            <div id="aboutCircle" className="circle"></div>
            <p className="bulletText">Add yourself to a cohort</p>
        </div>
        <div className='jobWrapper'>
            <div id="aboutCircle" className="circle"></div>
            <p className="bulletText">Edit your profile and add projects to your ‘portfolio’</p>
        </div>

      <p id="firstProjDesc">CCC was born out of our desire of being able to view past student’s work easily who had been on the same bootcamp as us.</p>
      <p id="firstProjDesc">My main contribution was implementing user login and profile edit functionality in backend and frontend, as well as to the styling and navigation of the app.</p>
      </div>
      &nbsp;

      <div className='projAbout'>
        <div className='spectral-semibold-italic'>
          Under Construction 
        </div>
        <div className='spectral-regular'>
          I'm currently working on making the app mobile responsive.
        </div>
      </div>
      
      <div id="projSkillsWrapper">
        <div className='innerProjSkillswrapper'>
          {/* <h4>Back End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Back End</div>
          </div>

          <div className='descSkills'>

          <div className="skillWrapper">
            <i className="devicon-javascript-plain"/>
            <p className="spectral-semibold skillText">JavaScript</p>
          </div>
          <div className="skillWrapper">
            <i class="devicon-mongodb-plain"></i>
            <p className="spectral-semibold skillText">MongoDB</p>
          </div>
          <div className="skillWrapper">
            <i class="devicon-express-original"></i>
            <p className="spectral-semibold skillText">Express</p>
          </div>
          <div className="skillWrapper">
            <i class="devicon-nodejs-plain"></i>
            <p className="spectral-semibold skillText">Node</p>
          </div>

          </div>
        </div>
        <div className='innerProjSkillswrapper'>
          {/* <h4>Front End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Front End</div>
          </div>

          <div className='descSkills'>

            <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-react-original"></i>
              <p className="spectral-semibold skillText">React</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-axios-plain-wordmark"></i>
              <p className="spectral-semibold skillText">Axios</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-bootstrap-plain"></i>
              <p className="spectral-semibold skillText">Bootstrap</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-css3-plain"></i>
              <p className="spectral-semibold skillText">CSS3</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-html5-plain"></i>
              <p className="spectral-semibold skillText">HTML5</p>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  )
}
