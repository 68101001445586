import { useParams, Link } from "react-router-dom"
import Birthdays from "../ProjectDescriptions/Birthdays/Birthdays"
import CCC from "../ProjectDescriptions/CCC/CCC"
import ShootFinder from "../ProjectDescriptions/ShootFinder/ShootFinder"
import Frogger from "../ProjectDescriptions/Frogger/Frogger"
import Caterpillar from "../ProjectDescriptions/Caterpillar/Caterpillar"
import "./Project.css"

export default function Project({projects}) {
    const { url } = useParams()
    const selectedProject = projects.find((project) => project.url === url)

    return (
    <div id="projectContainer">
        <link rel="stylesheet" type='text/css' href="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css" />
        <div id="projectWrapper">
            <div id="projectLinksWrapper">
                <Link to={selectedProject.repoF} target="blank">
                    <div className="projectLink"><strong>Github<br></br>FrontEnd</strong></div>
                </Link>
                {selectedProject.repoB
                ?
                <Link to={selectedProject.repoB} target="blank">
                    <div className="projectLink"><strong>Github<br></br>BackEnd</strong></div>
                </Link>
                :
                <></>
                }
                <Link to={selectedProject.link} target="blank">
                    <div className="projectLink"><strong>Deploy<br></br>Link</strong></div>
                </Link>
            </div>
            <img src={selectedProject.image} alt="" id="projectImage"/>
        </div>
        <div id="projectDescriptionWrapper">
            <div id="projectTitle" className="spectral-bold-italic">{selectedProject.title}</div>
            {selectedProject.title === "Birthdays"
            ?
            <Birthdays />
            :
            <></>
            }
            {selectedProject.title === "Crash Course Collective"
            ?
            <CCC />
            :
            <></>
            }
            {selectedProject.title === "Shoot Finder"
            ?
            <ShootFinder />
            :
            <></>
            }
            {selectedProject.title === "Frogger"
            ?
            <Frogger />
            :
            <></>
            }
            {selectedProject.title === "Caterpillar"
            ?
            <Caterpillar />
            :
            <></>
            }
        </div>
        <div id="home-wrapper-skills">
            <Link to="/projects" id="home-button-skills">
            <div>back</div>
            </Link>
        </div>
    </div>
  )
}
