import { Link } from "react-router-dom"
import "./About.css"

export default function About() {
  return (
    <div id="aboutContainer">

      <div id="aboutHeader">
        <div id="photoContainer">
          <div id="myPhoto"></div>
        </div>
        <div>
          <h2>I'm Joelle, a Junior Software Engineer and recent graduate of the Software Engineering Bootcamp at General Assembly.</h2>
        </div>
      </div>

        <p className="aboutText spectral-bold" id="aboutText-1">From eight years of working in fashion and costume production, I have cemented a concise and intentional approach to both functionality and aesthetics, never compromising on either.</p>

        <div id="aboutMidContainer">
          <div id="workPhoto"></div>
          <div id="aboutMidTextContainer">
            <p className="aboutText spectral-medium" id="aboutText-2">I've seen the massive importance and presence of digital skills in the creative industry and wanted to secure these tools for myself. </p>
            <p className="aboutText spectral-medium" id="aboutText-2">After gaining so much experience working with my hands and tactile materials, I was ambitious to expand and elevate on my existing skills as well as challenge myself with a different way of thinking.</p>
          </div>
        </div>

        <p className="aboutText spectral-medium" id="aboutText-4">My interest in fashion, e-commerce and the undeniable use of innovative technology in this field sparked my curiosity in software engineering and the ability to be creative digitally, in both function and form.</p>
        
        <div id="moreWrapper">
          <div className="spectral-bold" id="more">more about me...</div>
        </div>

        <div className="moreAbout">
          <div className="moreAboutTitle-1 spectral-bold-italic">Knitting/ Crocheting</div>
          <div className="moreAboutText moreAboutText-1 spectral-regular">
          I love knitting for myself and friends and family, experimenting with different yarns and colours. My latest project for myself is a knitted sweater vest in brown bamboo cotton yarn.
          </div>
        </div>

        <div className="moreAbout">
          <div className="moreAboutTitle-2 spectral-bold-italic">UX Design</div>
          <div className="moreAboutText moreAboutText-2 spectral-regular">
          I’m fascinated with the way data is displayed on websites and apps; in particular e-commerce (which goes hand in hand with my love of fashion and shopping).         
          </div>
        </div>


        
        <div id="home-wrapper-about">
          <Link to="/" id="home-button">
            <div>home</div>
          </Link>
        </div>
    </div>
  )
}
