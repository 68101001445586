import React from 'react'

export default function Caterpillar() {
  return (
    <div className="projDescContainer">
      <div className="projAbout spectral-medium">
        <p id="firstProjDesc">The first project in General Assembly’s Software Engineering Immersive course, and my first ever coding project.</p>
        <p id="firstProjDesc">Created using pure JavaScript, HTML, and CSS, I  designed and rendered my own Caterpillar version of the classic Snake game in the browser.</p>
        <p id="firstProjDesc">This game is desktop and mobile responsive, with the logo and caterpillar animation designed by myself.</p>
        <p id="firstProjDesc">The aim of the game is to eat as much food as you can. The more you eat, the longer the caterpillar becomes and the faster it moves. Eat the randomly appearing fruit for bonus points.</p>
      </div>

      <div className="projImageMobileWrapper">
        <div className="projImageTextWrapperSmall">
          <img src={require("./caterpillar02.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Mobile view</p>
        </div>
      </div>
      &nbsp;

      <div id="projSkillsWrapper">
        <div className="innerProjSkillswrapper">
          <div className="descSkills">

            <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-css3-plain"></i>
              <p className="spectral-semibold skillText">CSS3</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-html5-plain"></i>
              <p className="spectral-semibold skillText">HTML5</p>
            </div>

          </div>
        </div>
      </div>
      
    </div>

  )
}
