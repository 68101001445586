import { Link } from "react-router-dom"
import "./Skills.css"
import $ from "jquery"

export default function Skills() {
  $(function() {
    $(".skillWrapper").hide().slideDown(500)
  })

  return (
    <div className="container">
      <link rel="stylesheet" type='text/css' href="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css" />
      <div id="skillsShape">
      <h4 id="skills">My Skills</h4>
      <div id="skillsWrapper">
        <div className="skillWrapper">
          <i className="devicon-javascript-plain"/>
          <p className="spectral-semibold skillText">JavaScript</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-trpc-plain"></i>
          <p className="spectral-semibold skillText">TypeScript</p>
        </div>
        <div className="skillWrapper">
          <i className="devicon-python-plain"></i>
          <p className="spectral-semibold skillText">Python</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-react-original"></i>
          <p className="spectral-semibold skillText">React</p>
        </div>
        <div className="skillWrapper">
          <i className="devicon-angular-plain"></i>
          <p className="spectral-semibold skillText">Angular</p>
        </div>
        <div className="skillWrapper">
        <i class="devicon-jquery-plain-wordmark"></i>
        <p className="spectral-semibold skillText">jQuery</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-vuejs-plain"></i>
          <p className="spectral-semibold skillText">Vue.js</p>
        </div>
        <div className="skillWrapper">
          <i className="devicon-django-plain"/>
          <p className="spectral-semibold skillText">Django</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-mongodb-plain"></i>
          <p className="spectral-semibold skillText">MongoDB</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-postgresql-plain"></i>
          <p className="spectral-semibold skillText">PostgreSQL</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-tailwindcss-original"></i>
          <p className="spectral-semibold skillText">TailwindCSS</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-bootstrap-plain"></i>
          <p className="spectral-semibold skillText">Bootstrap</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-css3-plain"></i>
          <p className="spectral-semibold skillText">CSS3</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-html5-plain"></i>
          <p className="spectral-semibold skillText">HTML5</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-express-original"></i>
          <p className="spectral-semibold skillText">Express</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-nodejs-plain"></i>
          <p className="spectral-semibold skillText">Node</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-bash-plain"></i>
          <p className="spectral-semibold skillText">Bash</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-npm-original-wordmark"></i>
          <p className="spectral-semibold skillText">npm</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-github-original colored"></i>
          <p className="spectral-semibold skillText">GitHub</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-axios-plain-wordmark"></i>
          <p className="spectral-semibold skillText">Axios</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-amazonwebservices-plain-wordmark"></i>
          <p className="spectral-semibold skillText">AWS</p>
        </div>
        <div className="skillWrapper">
        <i class="devicon-djangorest-plain"></i>
          <p className="spectral-semibold skillText">Django Rest Framework</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-oauth-plain"></i>
          <p className="spectral-semibold skillText">OAuth</p>
        </div>
      </div>

      <h4 id="skills">I'm Currently learning:</h4>
      <div id="skillsWrapper">
        <div className="skillWrapper">
          <i class="devicon-sass-original"></i>
          <p className="spectral-semibold skillText">Sass</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-wordpress-plain"></i>
          <p className="spectral-semibold skillText">WordPress</p>
        </div>
        <div className="skillWrapper">
          <i class="devicon-php-plain"></i>
          <p className="spectral-semibold skillText">PHP</p>
        </div>


      </div>

      </div>

      <div id="home-wrapper-skills">
        <Link to="/" id="home-button-skills">
          <div>home</div>
        </Link>
      </div>

    </div>
  )
}
