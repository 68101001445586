import { Link } from "react-router-dom"
import "./Navbar.css"
import "../../index.css"

export default function Navbar() {
  return (
    <div id="navbar">
        <div id="nav-burst-1" className="hover-animation"></div>
        <div id="nav-burst-2" className="hover-animation-1"></div>
        <div id="nav-burst-3" className="hover-animation-2"></div>
        <div id="nav-burst-4" className="hover-animation-2"></div>

        <Link to="/about" id="nav-1-wrapper">
            <div id="nav-1" className="spectral-regular">about</div>
        </Link>
        <Link to="/projects" id="nav-2-wrapper">
            <div id="nav-2" className="spectral-regular">projects</div>
        </Link>
        <Link to="/skills" id="nav-3-wrapper">
            <div id="nav-3" className="spectral-regular">skills</div>
        </Link>

        <Link to="/contact" id="nav-4-wrapper">
            <div id="nav-4" className="spectral-regular">contact</div>
        </Link>
        <Link to="/experience" id="nav-5-wrapper">
            <div id="nav-5" className="spectral-regular">experience</div>
        </Link>

        <div id="nav-2-b"></div>
        <div id="nav-3-b"></div>
        <div id="nav-4-b"></div>
    </div>
  )
}
