import React from 'react'

export default function Frogger() {
  return (
    <div className="projDescContainer">
      <div className="projAbout spectral-medium">
        <p id="firstProjDesc">My Christmas project in General Assembly’s Software Engineering Immersive course.</p>
        <p id="firstProjDesc">Created using pure JavaScript, HTML, and CSS, I  designed and rendered my own take on the classic Frogger game in the browser.</p>
        <p id="firstProjDesc">The aim of the game is to get the family of five frogs across the busy road and dangerous river, before the timer runs out!</p>
        <p id="firstProjDesc">This game is desktop and mobile responsive.</p>
      </div>
      
      <div className="projImageMobileWrapper">
        <div className="projImageTextWrapperSmall">
          <img src={require("./frogger02.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Mobile view</p>
        </div>
      </div>
      &nbsp;

      <div id="projSkillsWrapper">
        <div className="innerProjSkillswrapper">
          <div className="descSkills">

            <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-css3-plain"></i>
              <p className="spectral-semibold skillText">CSS3</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-html5-plain"></i>
              <p className="spectral-semibold skillText">HTML5</p>
            </div>

          </div> 
        </div>
      </div>


    </div>
  )
}
