import { Routes, Route, Link } from "react-router-dom"
import Home from "./components/Home/Home.jsx"
import About from "./components/About/About"
import Projects from "./components/Projects/Projects.jsx"
import Project from "./components/Projects/Project.jsx"
import Skills from "./components/Skills/Skills.jsx"
import Contact from "./components/Contact/Contact.jsx"
import "./App.css"
import "./Bursts.css"
import Experience from "./components/Experience/Experience.jsx"


function App() {
  const projects = [
    {
      title: "Birthdays",
      url: "birthdays",
      image: "/birthdays1.png",
      repoF: "https://github.com/JoelleLi/Unit4_Project_Frontend",
      repoB: "https://github.com/JoelleLi/Unit4_Project_Backend",
      link: "https://birthdays.up.railway.app/"
    },
    {
      title: "Crash Course Collective",
      url: "CCC",
      image: "/ccc1.png",
      repoF: "https://github.com/JoelleLi/Unit3_Project_CrashCourseCollective_Frontend",
      repoB: "https://github.com/JoelleLi/Unit3_Project_CrashCourseCollective_Backend",
      link: "https://crashcoursecollective.netlify.app/",
      description: ""
    },
    {
      title: "Shoot Finder",
      url: "shootfinder",
      image: "/shootfinder1.png",
      repoF: "https://github.com/JoelleLi/Unit2_Project_FrontEnd",
      repoB: "https://github.com/JoelleLi/Unit2_Project_BackEnd",
      link: "https://shoot-finder.netlify.app/",
      description: ""
    },
    {
      title: "Frogger",
      url: "frogger",
      image: "/frogger1.png",
      repoF: "https://github.com/JoelleLi/Unit1_Project_Frogger",
      link: "https://joelleli.github.io/Unit1_Project_Frogger/",
      description: ""
    },
    {
      title: "Caterpillar",
      url: "caterpillar",
      image: "/caterpillar1.png",
      repoF: "https://github.com/JoelleLi/Unit1_Project_Snake",
      link: "https://joelleli.github.io/Unit1_Project_Snake/",
      description: ""
    },
  ]

  return (
    <div className="App spectral-extralight">

      <div className="appHeader">
        <div id="burst-1"></div>
        <div id="name">
          <div id="burst-2"></div>
          <div id="header">
            <div id="topRectangleWrapper">
              <div id="rectangle2"></div>
              <div id="rectangle3"></div>
            </div>
            <div className="bigCircle"></div>
            <div id="bottomRectangleWrapper">
              <div id="rectangle1"></div>
              <div id="rectangle4"></div>
            </div>
            <Link to="/"> 
              <h2>Joelle Li-Cho</h2>
            </Link>
            <h5>Junior Software Developer</h5>
            <div id="circleContainer">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </div>
          <div id="burst-3"></div>
        </div>
      </div>


      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/projects" element={ <Projects projects={projects}  /> } />
        <Route path="/projects/:url" element={ <Project projects={projects} /> } />
        <Route path="/skills" element={ <Skills /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/experience" element={ <Experience /> }></Route>
      </Routes>
    </div>
  );
}

export default App;
