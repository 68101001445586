import { Link } from "react-router-dom"
import "./Projects.css"
import ProjectCard from "../ProjectCard/ProjectCard"

export default function Projects({projects}) {

  return (
    <div className="projectContainer">
      {projects.map(project => (
        <>
        <Link to={`/projects/${project.url}`}>
          <ProjectCard project={project}/>
        </Link>
        </>
      ))}   
      <div id="home-wrapper-skills">
        <Link to="/" id="home-button-skills">
          <div>home</div>
        </Link>
      </div>
    </div>
  )
}
