import React from 'react'

export default function ShootFinder() {
  return (
    <div className="projDescContainer">
      <div className="projAbout spectral-medium">

      <p id="firstProjDesc">Shoot Finder is an online community where users can view and post their own private photoshoot locations, as well as flag public / nature locations.</p>
      <p id="firstProjDesc">This is my first project creating a full stack application using MongoDB, Express, Vue.js, Node and Google OAuth. Users can:</p>

      <div className='jobWrapper'>
        <div id="aboutCircle" className="circle"></div>
        <p className="bulletText">Log in using Google OAuth.</p>
      </div>

      <div className='jobWrapper'>
        <div id="aboutCircle" className="circle"></div>
        <p className="bulletText">Create, read, update and delete their own listings.</p>
      </div>

      <div className='jobWrapper'>
        <div id="aboutCircle" className="circle"></div>
        <p className="bulletText">Add examples of their own photoshoot images to a guest image gallery for each listing.</p>
      </div>

      <div className='jobWrapper'>
        <div id="aboutCircle" className="circle"></div>
        <p className="bulletText">Search listings by name / key word</p>
      </div>

      <div className='jobWrapper'>
        <div id="aboutCircle" className="circle"></div>
        <p className="bulletText">Browse listings filtered by category such as by city, public or private</p>
      </div>

      </div>

      <div className="projImageMobileWrapper">
        <div className="projImageTextWrapper">
          <img src={require("./shootfinder01.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Search listings by name/ key word</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./shootfinder02.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText"> Listing mobile view</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./shootfinder03.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Add your own guest images to a listing when logged in</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./shootfinder04.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Edit or delete your listing when logged in</p>
        </div>
        <div className="projImageTextWrapper">
          <img src={require("./shootfinder05.png")} alt="" className="projImageMobile"/>
          <p className="spectral-semibold-italic projImageText">Log in using Google OAuth</p>
        </div>
      </div>

      <div id="projSkillsWrapper">
        <div className="innerProjSkillswrapper">
          {/* <h4>Back End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Back End</div>
          </div>

          <div className="descSkills">

            <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-mongodb-plain"></i>
              <p className="spectral-semibold skillText">MongoDB</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-express-original"></i>
              <p className="spectral-semibold skillText">Express</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-nodejs-plain"></i>
              <p className="spectral-semibold skillText">Node</p>
            </div>

          </div>
        </div>

        <div className="innerProjSkillswrapper">
          {/* <h4>Front End</h4> */}

          <div className="contact-circle">
            <div className="spectral-bold">Front End</div>
          </div>

          <div className="descSkills">

            <div className="skillWrapper">
              <i className="devicon-javascript-plain"/>
              <p className="spectral-semibold skillText">JavaScript</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-vuejs-plain"></i>
              <p className="spectral-semibold skillText">Vue.js</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-oauth-plain"></i>
              <p className="spectral-semibold skillText">OAuth</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-bootstrap-plain"></i>
              <p className="spectral-semibold skillText">Bootstrap</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-css3-plain"></i>
              <p className="spectral-semibold skillText">CSS3</p>
            </div>
            <div className="skillWrapper">
              <i class="devicon-html5-plain"></i>
              <p className="spectral-semibold skillText">HTML5</p>
            </div>
        
          </div>
        </div>
      </div>

    </div>
  )
}
